<template>
  <!-- begin::Tracking incidents edit -->
  <div>
    <div class="manager-title">
      <div>
        {{ $t("SHIPMENTS.INCIDENTS.TITLE_ADD") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box pt-6">
      <v-form ref="formIncident" v-model="Valid" lazy-validation class="form">
        <div class="row">
          <div class="form-group col-lg-4 mb-0">
            <label>
              {{ $t("SHIPMENTS.INCIDENTS.TYPE") }}
            </label>
          </div>

          <div class="form-group col-lg-8 mb-0">
            <label>
              {{
                $t("SHIPMENTS.INCIDENTS.PRICE_INCREASE") +
                  " (" +
                  this.Fields.Currency +
                  ", " +
                  $t("SHIPMENTS.TAXES_INCLUDED") +
                  ")"
              }}
            </label>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <select
              v-if="ShipmentLegVehicleIncidentID < 1"
              class="form-control select-down"
              v-model="Fields.IncidentTypeID"
              required
              :rules="[rules.required]"
              @change="onChangeIncidentType"
            >
              <option
                v-for="it in IncidentTypeOptions"
                :key="it.IncidentTypeID"
                :value="it.IncidentTypeID"
                class="text-primary"
              >
                {{ it.Name }}
              </option>
            </select>
            <v-text-field
              v-else
              class="form-control"
              :value="Fields.IncidentTypeName"
              readonly
            />
          </div>

          <div class="form-group col-lg-4">
            <v-text-field
              class="form-control"
              v-model="Fields.PriceIncrease"
              type="number"
              min="0"
              step="0.01"
              :rules="[rules.requiredMin0]"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("INCIDENTS.TYPES.CANCELS_2") }}
            </label>
            <v-text-field
              class="form-control"
              :value="
                Fields.CausesCancellation ? $t('GENERIC.YES') : $t('GENERIC.NO')
              "
              readonly
            />
          </div>

          <!--begin::CancelDate-->
          <div v-if="Fields.CausesCancellation" class="form-group col-lg-4">
            <label>
              {{ $t("SHIPMENTS.INCIDENTS.CANCEL_DATE") }}
            </label>
            <v-text-field
              v-if="Fields.FinishedAt.length > 0"
              class="form-control"
              :value="formatDate(Fields.CancelDate)"
              readonly
            />
            <v-text-field
              v-else
              class="form-control"
              :value="formatDate(Fields.CancelDate)"
              @click="openCancelDateDialog()"
            />

            <!--begin::CancelDate dialog-->
            <v-dialog
              v-model="CancelDateDialog.Show"
              :return-value="CancelDateDialog.Value"
              persistent
              width="320px"
            >
              <v-date-picker
                v-model="CancelDateDialog.Value"
                color="tertiary"
                scrollable
                :locale="DateLocale"
                :first-day-of-week="DateFirstDayOfWeek"
                :min="OneYearAgo"
                :max="Today"
              >
                <div class="row button-row">
                  <button
                    class="btn btn-pill btn-white button-form button-form-primary mr-9"
                    style="min-width: 125px"
                    @click="cancelCancelDateDialog"
                  >
                    {{ $t("GENERIC.CANCEL") }}
                  </button>
                  <button
                    class="btn btn-pill btn-tertiary button-form"
                    style="min-width: 125px"
                    @click="saveCancelDateDialog"
                  >
                    {{ $t("GENERIC.OK") }}
                  </button>
                </div>
              </v-date-picker>
            </v-dialog>
            <!--end::CancelDate dialog-->
          </div>
          <!--end::CancelDate-->
        </div>

        <div class="row">
          <div class="form-group col-lg-8">
            <label>
              {{ $t("SHIPMENTS.INCIDENTS.NOTES") }}
              <span class="font-weight-normal">
                ({{ $t("GENERIC.OPTIONAL") }})
              </span>
            </label>
            <textarea
              rows="3"
              class="form-control"
              v-model="Fields.Notes"
              :placeholder="$t('SHIPMENTS.INCIDENTS.NOTES_INFO')"
            ></textarea>
          </div>
        </div>

        <div class="row mb-0">
          <div class="col-lg-12 form-group mb-0">
            <label v-if="Fields.FinishedAt.length > 0">
              {{
                $t("SHIPMENTS.INCIDENTS.FINISHED") +
                  " " +
                  formatDateTime(Fields.FinishedAt)
              }}
            </label>
            <v-checkbox
              v-else
              v-model="Fields.IsFinished"
              style="margin-bottom: -1em"
            >
              <template v-slot:label>
                <div class="pt-1 ml-3 text-dark font-weight-normal">
                  {{ $t("SHIPMENTS.INCIDENTS.MARK_FINISHED") }}
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="goBack()"
        >
          <inline-svg
            src="/media/icons/arrow-return-primary.svg"
            class="mr-3"
          />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::Tracking incidents edit -->
</template>

<script>
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import IncidentTypeService from "@/core/services/api/v2/incidenttype.service";
import ShipmentService from "@/core/services/api/v2/shipment.service";

export default {
  name: "TrackingIncidentsEdit",
  data() {
    return {
      ShipmentLegVehicleID: 0,
      ShipmentLegVehicleIncidentID: 0,
      IncidentTypeOptions: [],
      Valid: true,
      Fields: {
        IncidentTypeID: 0,
        IncidentTypeName: "",
        PriceIncrease: 0,
        Currency: "",
        Notes: "",
        CausesCancellation: false,
        CancelDate: "",
        IsFinished: false,
        FinishedAt: ""
      },
      CancelDateDialog: {
        Show: false,
        Value: ""
      },
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY",
      DateFirstDayOfWeek: 1,
      DateLocale: "es-es",
      Today: "",
      OneYearAgo: "",
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        requiredMin0: value => {
          let value2 = parseInt(value);
          if (isNaN(value2) || value2 < 0) {
            return this.$i18n.t("VALIDATION.MIN_VALUE", { min: 0 });
          } else {
            return true;
          }
        }
      }
    };
  },

  created() {
    // Pick format for dates
    let userLang = i18nService.getActiveLanguage();
    if (userLang === "en") {
      this.DateFirstDayOfWeek = 0;
      this.DateLocale = "en-en";
      this.DateFormatLang = this.DateFormatEN;
    } else {
      this.DateFirstDayOfWeek = 1;
      this.DateLocale = "es-es";
      this.DateFormatLang = this.DateFormatES;
    }

    this.Today = moment().format(this.DateFormatEN);
    this.OneYearAgo = moment()
      .subtract(1, "years")
      .format(this.DateFormatEN);

    // Pick incident types
    this.loadIncidentTypes();
  },

  mounted() {
    // Edit (a certain "incident id") or create new?
    if (!this.$route.params.id) {
      this.goBack();
    } else {
      this.ShipmentLegVehicleID = parseInt(this.$route.params.id);

      if (this.$route.params.idI) {
        this.ShipmentLegVehicleIncidentID = parseInt(this.$route.params.idI);
        this.loadIncidentData();
      } else {
        this.ShipmentLegVehicleIncidentID = -1;
        this.Fields.Currency = "EUR";
      }
    }
  },

  methods: {
    loadIncidentTypes() {
      this.IncidentTypeOptions = [];

      IncidentTypeService.search("", "", "Name", false, 99, 0).then(
        rawResponse => {
          if (
            !rawResponse.response.IncidentTypes ||
            rawResponse.response.IncidentTypes.length === 0
          ) {
            this.goBack();
          } else {
            this.IncidentTypeOptions = rawResponse.response.IncidentTypes;

            // On new incidents, pick first option default
            if (this.ShipmentLegVehicleIncidentID < 1) {
              this.Fields.IncidentTypeID = this.IncidentTypeOptions[0].IncidentTypeID;
              this.onChangeIncidentType();
            }
          }
        }
      );
    },

    loadIncidentData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      ShipmentService.getIncidentAtTracking(
        this.ShipmentLegVehicleID,
        this.ShipmentLegVehicleIncidentID
      ).then(response => {
        if (response === 404) {
          this.goBack();
        } else {
          for (var f1 in this.Fields) {
            this.Fields[f1] = response[f1];
          }

          this.Fields.IsFinished = this.Fields.FinishedAt.length > 0;

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    onChangeIncidentType() {
      this.IncidentTypeOptions.forEach(t => {
        if (t.IncidentTypeID === this.Fields.IncidentTypeID) {
          this.Fields.IncidentTypeName = t.Name;
          this.Fields.CausesCancellation = t.CausesCancellation;
        }
      });
    },

    formatDate(date) {
      if (date && date.length > 0) {
        return moment(date).format(this.DateFormatLang);
      } else {
        return "-";
      }
    },
    formatDateTime(datetime) {
      if (datetime && datetime.length > 0) {
        return (
          this.formatDate(datetime) + " " + moment(datetime).format("HH:mm:ss")
        );
      } else {
        return "-";
      }
    },

    openCancelDateDialog() {
      if (this.Fields.CausesCancellation) {
        let oldValue = this.Fields.CancelDate;

        // From 2018 to 2021 no Transporter bothers to fill the CancelDates hour;
        // that's why we don't use a date-time-picker, just pad it
        this.CancelDateDialog.Value =
          oldValue.length > 0 ? moment(oldValue).format(this.DateFormatEN) : "";

        this.CancelDateDialog.Show = true;
      }
    },

    saveCancelDateDialog() {
      if (this.Fields.CausesCancellation) {
        let newDate = this.CancelDateDialog.Value;

        // From 2018 to 2021 no Transporter bothers to fill the DoneDates hour;
        // that's why we don't use a date-time-picker, just pad it
        this.Fields.CancelDate = newDate + " 00:00:00";
      }

      this.CancelDateDialog.Show = false;
    },

    cancelCancelDateDialog() {
      if (this.Fields.CausesCancellation) {
        // Reset picker to old date
        let oldDate = this.Fields[this.CancelDate];
        this.CancelDateDialog.Value = oldDate;
      }

      this.CancelDateDialog.Show = false;
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formIncident.validate()) {
        // Disable submit button
        this.Valid = false;

        // From 2018 to 2021 no Transporter bothers to fill the CancelDate hour;
        // that's why we don't use a date-time-picker, just pad it
        if (this.Fields.CancelDate.length > 0) {
          this.Fields.CancelDate =
            moment(this.Fields.CancelDate).format(this.DateFormatEN) +
            " 00:00:00";
        }

        // Send request:
        if (this.ShipmentLegVehicleIncidentID > 0) {
          // Update Incident
          ShipmentService.updateIncidentAtTracking(
            this.ShipmentLegVehicleID,
            this.ShipmentLegVehicleIncidentID,
            this.Fields
          ).then(statusCode => {
            if (statusCode >= 400) {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (statusCode === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });
            }

            // Reset submit button and reload, because maybe changed its status
            this.Valid = true;
            this.loadIncidentData();
          });
        } else {
          // Create new Incident: we get back its ID
          ShipmentService.createIncidentAtTracking(
            this.ShipmentLegVehicleID,
            this.Fields
          ).then(response => {
            if (response.ShipmentLegVehicleIncidentID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.ShipmentLegVehicleIncidentID =
                response.ShipmentLegVehicleIncidentID;

              setTimeout(
                () =>
                  this.$router.push(
                    "/manager/shipments/edit/" +
                      this.ShipmentLegVehicleID +
                      "/incidents/edit/" +
                      this.ShipmentLegVehicleIncidentID
                  ),
                2000
              );
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        }
      }
    },

    goBack() {
      let routeBack = "/manager/shipments";
      if (this.ShipmentLegVehicleID > 0) {
        routeBack +=
          "/edit/" + this.ShipmentLegVehicleID + "?ActiveTab=incidents";
      }

      this.$router.push(routeBack);
    }
  }
};
</script>
