import ApiService from "@/core/services/api/v2/api.service";

const IncidentTypeService = {
  getProfile(incidentTypeID) {
    return new Promise(resolve => {
      ApiService.get("incidentType/" + incidentTypeID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateProfile(incidentTypeID, newData) {
    return new Promise(resolve => {
      ApiService.put("incidentType/" + incidentTypeID, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteProfile(incidentTypeID) {
    return new Promise(resolve => {
      ApiService.delete("incidentType/" + incidentTypeID)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createProfile(newData) {
    return new Promise(resolve => {
      ApiService.put("incidentType", newData)
        .then(response => {
          // Response (if correct) holds the new IncidentTypeID
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  search(
    searchText,
    filterByCausesCancellation,
    orderBy,
    orderDesc,
    limit,
    offset
  ) {
    return new Promise(resolve => {
      // Parse params
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByCausesCancellation = filterByCausesCancellation.trim();
      if (filterByCausesCancellation.length > 0) {
        queryParams.CausesCancellation = filterByCausesCancellation;
      }

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      ApiService.query("incidentType", { params: queryParams })
        .then(response => {
          // Return full response, not just "IncidentTypes" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  }
};

export default IncidentTypeService;
